import axios from "axios";
import { userService } from "./user.service.js";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// intercepteur pour le token
Axios.interceptors.request.use((request) => {
  if (request.url === "/api/Payment/config") {
    return Promise.resolve(request);
  }

  if (userService.isLoggedIn() === true) {
    const cb = () => {
      request.headers.Authorization = `Bearer ${userService.getToken()}`;
      return Promise.resolve(request);
    };

    return userService.updateToken(cb);
  } else {
    return userService.doLogin();
  }
});

export default Axios;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "../../utils/_services/user.service.js";
import { NotificationManager } from "react-notifications";

const initialState = {
  user: {},
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchUser = createAsyncThunk("users/fetchUser", async () => {
  const response = await userService.getUserAsync();
  return response.data;
});

export const updateUser = createAsyncThunk("users/updateUser", async (user) => {
  const response = await userService.updateUser(user.id, user);
  return response.data;
});

export const updateUserPhoto = createAsyncThunk("users/updateUserPhoto", async (user) => {
  const response = await userService.updateUserPhoto(user.id, user.photo);
  return response.data;
});

export const updateUserBanner = createAsyncThunk("users/updateUserBanner", async (user) => {
  const response = await userService.updateUserBanner(user.id, user.banner);
  return response.data;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: { 
    resetUserStatus(state) {
    state.status = "idle"; // Reset status to 'idle' to trigger re-fetch
  },},
  extraReducers(builder) {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(fetchUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;      
        if (
          state.error !==
          "Cannot read properties of undefined (reading 'authorize')"
        ) {
          // alert(state.error);
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour du profil."
          );
        }
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
        NotificationManager.success(
          "Votre profil a été mis a jour"
        );
      })
      .addCase(updateUserPhoto.fulfilled, (state, action) => {
        state.user = action.payload;
        NotificationManager.success(
          "Votre photo a été mis a jour"
        );
      })
      .addCase(updateUserBanner.fulfilled, (state, action) => {
        state.user = action.payload;
        NotificationManager.success(
          "Votre Bannière a été mis a jour"
        );
      });
  },
});

export const getCurrentUser = (state) => state.user.user;
export const getUserStatus = (state) => state.user.status;
export const getUserError = (state) => state.user.error;
export const { resetUserStatus } = userSlice.actions;

// export const { updateName } = userSlice.actions;

export default userSlice.reducer;

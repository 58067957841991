import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import withClearCache from "./ClearCache.js";
import i18n from "./i18n.js";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/css/btpGo.css";
import { I18nextProvider } from "react-i18next";
import Loading from "./components/Loading.js";
const AdminLayout = lazy(() => import("./layouts/Admin.js"));
const AuthLayout = lazy(() => import("./layouts/Auth.js"));
const Welcome = lazy(() => import("./layouts/Welcome.js"));
const Blog = lazy(() => import("./layouts/Blog.js"));
const WelcomProperty = lazy(() => import("./layouts/WelcomProperty.js"));
const WelcomeArtisans = lazy(() => import("./layouts/WelcomeArtisans.js"));
const Completion = lazy(() => import("./components/Payment/Completion.js"));
const Empty = lazy(() => import("./layouts/Empty.js"));

const ClearCacheComponent = withClearCache(MainApp);

export default function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  return (
    <I18nextProvider i18n={i18n("fr")}>
      <BrowserRouter >
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              path="/welcomeArtisans"
              render={(props) => <WelcomeArtisans {...props} />}
            />
            {process.env.REACT_APP_IS_COMMING_SOON === 'true' && <Route path="/launch" render={(props) => <Empty {...props} />} />}
            <Route
              path="/welcomeProperty"
              render={(props) => <WelcomProperty {...props} />}
            />
            {process.env.REACT_APP_IS_COMMING_SOON === 'false' && <Route path="/ap" render={(props) => <AdminLayout {...props} />} />}
            {process.env.REACT_APP_IS_COMMING_SOON === 'false' && <Route
              path="/completion"
              render={(props) => <Completion />}
            />}
            <Route path="/blog" render={(props) => <Blog {...props} />} />
            {process.env.REACT_APP_IS_COMMING_SOON === 'false' && <Route path="/admin" render={(props) => <AdminLayout {...props} />} />}
            {process.env.REACT_APP_IS_COMMING_SOON === 'false' && <Route path="/auth" render={(props) => <AuthLayout {...props} />} />}

            <Route exact path="/" render={(props) => <Welcome {...props} />} />
            {/* Remove duplicate route */}
            {/* <Route element={<Welcome />} /> */}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </I18nextProvider>
  );
}

import React from 'react';
import loading from "../assets/img/admin/Process.gif";

const Loading = () => {
    return <div className="d-flex justify-content-center align-items-center vh-100">
        <img className="loading" src={loading} alt=".." />
    </div>
};

export default Loading;
